<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <pro-deck
        :title="getRes('Document.AwardEvaluationCriteria')"
        :cards="deckCardList"
        hide-count-badge
        v-slot="{ card }"
      >
        <pro-deck-card :title="card.title" :actions="card.actions" expandable>
          <pro-tender-form-render
            mode="View"
            :code="card.viewFormCode"
            :tenderId="$route.params.id"
            :form-data-promise="card.formDataPromise"
          />
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProTenderFormRender from "@/components/PROSmart/FormBuilder/ProTenderFormRender";

const viewFormRouteName = "DocumentViewForm";

export default {
  components: { ProMenuLayout, ProDeck, ProDeckCard, ProTenderFormRender },
  data() {
    return {
      awardCriteria: {
        title: this.getRes("Document.AwardEvaluationCriteria.Award"),
        viewFormCode: "Procurer_Tender_ChangeOfCriteria_Form",
        editRoute: null,
        formDataPromise: null,
      },
      technicalEvalaution: [
        {
          title: this.getRes("Document.AwardEvaluationCriteria.TechPrelim"),
          viewFormCode: "Procurer_Tender_PreTechEvalCriteria_Form",
          editRoute: null,
          formDataPromise: null,
          display: false,
        },
        {
          title: this.getRes("Document.AwardEvaluationCriteria.TechStage1"),
          viewFormCode: "Procurer_Tender_Stage1EvalCrit_Form",
          editRoute: null,
          formDataPromise: null,
          display: false,
        },
        {
          title: this.getRes("Document.AwardEvaluationCriteria.TechStage2"),
          viewFormCode: "Procurer_Tender_Stage2EvalCrit_Form",
          editRoute: null,
          formDataPromise: null,
          display: false,
        },
      ],
    };
  },
  computed: {
    deckCardList() {
      return [
        this.awardCriteria,
        ...this.technicalEvalaution.filter((s) => s.display),
      ].map((s) => ({
        title: s.title,
        viewFormCode: s.viewFormCode,
        formDataPromise: s.formDataPromise,
        actions: s.editRoute
          ? [
              {
                label: this.getRes("System.Button.Edit"),
                to: s.editRoute,
              },
            ]
          : [],
      }));
    },
  },
  methods: {
    loadWorkflowInfoViewOnly(workflowCode) {
      return this.$proSmart.tender
        .getWorkflowDefaultResult(this, this.$route.params.id, workflowCode)
        .then((formData) => {
          formData.isViewOnly = true;
          return formData;
        });
    },
    getEditRoute(workflowCode) {
      return {
        name: viewFormRouteName,
        params: {
          mode: "Edit",
          code: workflowCode,
          stepperId: this.$route.params.stepperId,
        },
      };
    },
  },
  created() {
    Promise.all([
      this.$proSmart.tender.getListTechnicalEvaluationIsEnabled(
        this,
        this.$route.params.id
      ),
      this.$proSmart.tender.getWorkflowCode(this, this.$route.params.id, [
        "edit_award_criteria",
        "edit_prelim_techical_evaluation_criteria",
        "edit_stage1_techical_evaluation_criteria",
        "edit_stage2_techical_evaluation_criteria",
      ]),
    ]).then(([teEnabledList, [acWorkflowInfo, ...teWorkflowInfo]]) => {
      this.technicalEvalaution.forEach((te, i) => {
        te.display = teEnabledList[i];
        te.editRoute = teWorkflowInfo[i].visible
          ? this.getEditRoute(teWorkflowInfo[i].workflowCode)
          : null;
        te.formDataPromise = te.display
          ? (te.formDataPromise = this.loadWorkflowInfoViewOnly(
              teWorkflowInfo[i].workflowCode
            ))
          : null;
      });

      this.awardCriteria.editRoute = acWorkflowInfo.visible
        ? this.getEditRoute(acWorkflowInfo.workflowCode)
        : null;
      this.awardCriteria.formDataPromise = this.loadWorkflowInfoViewOnly(
        acWorkflowInfo.workflowCode
      );
    });
  },
};
</script>
